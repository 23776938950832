var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 sign-in--left-col",style:(("background-image:url('" + _vm.rightBgImg + "')")),attrs:{"lg":"7","xl":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"})]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5","xl":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto brand-logo-col",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Start your free trial! ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Try visitplanner on your website risk-free for 30 days. ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Your Name","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"name","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"John Doe"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Time Zone","label-for":"register-timezone"}},[_c('validation-provider',{ref:"timezone",attrs:{"name":"timezone","vid":"timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:[{'hasErrors' : errors.length > 0}],attrs:{"label":"Timezone","options":_vm.timezoneList},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{ref:"email",attrs:{"name":"Email","vid":"email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Church Site URL","label-for":"church-site-url"}},[_c('validation-provider',{ref:"church_site_url",attrs:{"name":"Church Site URL","vid":"church_site_url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"church-site-url","name":"church_site_url","state":errors.length > 0 ? false:null,"placeholder":"https://church-site.com"},model:{value:(_vm.churchSiteUrl),callback:function ($$v) {_vm.churchSiteUrl=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"churchSiteUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{ref:"tos",attrs:{"rules":"required","name":"Terms Acceptance","vid":"tos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"accept-terms","name":"tos","value":"tos-selected","state":errors.length > 0 ? false:null},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_vm._v(" I agree to "),_c('a',{attrs:{"href":"/privacy-policy","target":"_blank"}},[_vm._v(" privacy policy ")]),_vm._v(" & "),_c('a',{attrs:{"href":"/terms-of-service","target":"_blank"}},[_vm._v(" terms ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.isSubmitting}},[_vm._v(" Start Your Free Trial ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }