<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="7"
        xl="8"
        :style="`background-image:url('${rightBgImg}')`"
        class="d-none d-lg-flex align-items-center p-5 sign-in--left-col"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <!--  <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <b-col
        lg="5"
        xl="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto brand-logo-col"
        >
          <b-link class="brand-logo">
            <vuexy-logo />
          </b-link>
          <b-card-title class="mb-1">
            Start your free trial!
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- Brand logo-->

            <!-- /Brand logo-->
            Try visitplanner on your website risk-free for 30 days.
          </b-card-text>

          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-form-group
                label="Your Name"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="name"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="John Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Time Zone"
                label-for="register-timezone"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="timezone"
                  name="timezone"
                  vid="timezone"
                  rules="required"
                >
                  <v-select
                    v-model="timezone"
                    label="Timezone"
                    :options="timezoneList"
                    :class="[{'hasErrors' : errors.length > 0}]"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="email"
                  name="Email"
                  vid="email"
                  mode="eager"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model.trim="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Church Site URL"
                label-for="church-site-url"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="church_site_url"
                  name="Church Site URL"
                  vid="church_site_url"
                  rules="required"
                >
                  <b-form-input
                    id="church-site-url"
                    v-model.trim="churchSiteUrl"
                    name="church_site_url"
                    :state="errors.length > 0 ? false:null"
                    placeholder="https://church-site.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  ref="tos"
                  rules="required"
                  name="Terms Acceptance"
                  vid="tos"
                >
                  <b-form-checkbox
                    id="accept-terms"
                    v-model="terms"
                    name="tos"
                    value="tos-selected"
                    :state="errors.length > 0 ? false:null"
                  >
                    I agree to
                    <a
                      href="/privacy-policy"
                      target="_blank"
                    >
                      privacy policy
                    </a> &
                    <a
                      href="/terms-of-service"
                      target="_blank"
                    >
                      terms
                    </a>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || isSubmitting"
              >
                Start Your Free Trial
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { listTimeZones } from 'timezone-support'
import vSelect from 'vue-select'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import AuthService from '@/services/AuthService'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    vSelect,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isSubmitting: false,
      terms: [],
      name: '',
      userEmail: '',
      password: '',
      churchSiteUrl: '',
      timezone: '',
      timezoneList: [],
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      rightBgImg: require('@/assets/images/signup.jpg'),
      // validation
      required,
      email,
      baseToastTimeout: 2000,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // fill list of timezones and select user's local one
    this.timezoneList = listTimeZones()
    this.timezone = Intl.DateTimeFormat()
      .resolvedOptions().timeZone
    AuthService.logout()
  },

  methods: {
    register() {
      const self = this
      this.$refs.registerForm.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            const payload = {
              name: this.name,
              email: this.userEmail,
              password: this.password,
              timezone: this.timezone,
              church_site_url: this.churchSiteUrl,
            }
            try {
              AuthService.registerUser(payload)
                .then(() => this.$router.push({ name: 'set-payment-method' }))
                .catch(error => {
                  handleValidationError(error, this)
                  self.isSubmitting = false
                })
            } catch (error) {
              // API-related errors will be shown by the API error handler
              console.error(error)
              self.isSubmitting = false
            }
          }
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  .v-select.hasErrors [role="combobox"]{
    border-color:#ea5455 !important;
  }
  .v-select.hasErrors [role="combobox"].vs__dropdown-toggle .vs__selected{
    color:#ea5455;
  }
  .v-select.hasErrors .vs__clear svg{
    fill:#ea5455;
  }
  .brand-logo-col .brand-logo{
    position: relative !important;
    left: auto !important;
    margin-left: 0;
    display: flex;
    justify-content: center !important;
    top: auto !important;
    margin-bottom: 2rem !important;
    margin-right: 0;
    padding-right: 0;
    width: 100%;
    max-width: 100%;
  }
  .sign-in--left-col{
    position:relative;
    background-size:cover;
    background-position: center center;
  }
  .sign-in--left-col:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.5);
    z-index:9;
  }
</style>
<style scoped>
@media screen and (max-width: 991px){
  .auth-bg{
    padding-top: 3rem !important;
    align-items: flex-start !important;
  }
}
</style>
